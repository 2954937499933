import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Curriculum = ({ data }) => {
  return (
    <Layout>
      <SEO title="Curriculum" />
      <div className="container mx-auto w-full py-8">
        <h1>Curriculum</h1>
        {data.allCurriculum.nodes.map(obj => (
          <div key={obj.id}>
            <Link to={obj.url}>{obj.full_name}</Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CurriculumQuery {
    allCurriculum {
      nodes {
        id
        subject_name
        full_name
        obj_type
        url
      }
    }
  }
`

export default Curriculum
